<script>
export default {
	data() {
		return { items: this.$srv('About_Us_InfoWithImage', []) }
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<Container max-width="1240px" width="100%">
		<v-row v-for="(item, index) of items" :key="index" class="px-md-15 mb-15 d-flex align-center">
			<v-col
				cols="12"
				sm="8"
				:order="item.inverted || isMobile ? 3 : 1"
				class="px-10 d-flex flex-column justify-space-between"
			>
				<div>
					<div
						class="t1 font-weight-medium text-uppercase gold--text"
						:class="isMobile && 'text-center'"
						:style="isMobile && 'font-size: 20px;'"
					>
						{{ item.title }}
					</div>
					<div class="t3 font-weight-black mt-5 preline" :class="isMobile && 'text-center'">
						{{ item.text }}
					</div>
				</div>
				<div
					class="t5 font-weight-bold text-uppercase mt-5 d-flex align-center"
					:class="!item.inverted && 'justify-end'"
				>
					{{ item.description }}
				</div>
			</v-col>
			<v-col cols="12" sm="4" order="2" class="px-10">
				<Media :src="isMobile ? item.imgMobile : item.img" contain></Media>
			</v-col>
		</v-row>
	</Container>
</template>
