<script>
export default {
	data() {
		return { items: this.$srv('About_Us_Header') }
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		title() {
			return this.items.title.split(' ')
		},
	},
}
</script>
<template>
	<div>
		<v-row class="pa-0">
			<v-col
				cols="12"
				md="5"
				class="d-flex flex-column align-center justify-center text-uppercase px-2 px-md-15"
				:style="isMobile && 'transform: translateY(-7.5rem); z-index: 10;'"
				:order="isMobile ? 3 : 1"
			>
				<h1 style="line-height: 62px" :class="{ 'mobile-title text-center': isMobile }">
					<span
						v-for="(word, index) of title"
						:key="index"
						:class="{ 'ml-15': index === 0 || index === 3 }"
					>
						{{ word }}
						<br v-if="index === 0 || index === 2" />
					</span>
				</h1>
				<div class="t1 font-weight-bold text-center mt-5" style="max-width: 20rem">
					{{ items.subtitle }}
				</div>
			</v-col>
			<v-col cols="12" md="7" order="2">
				<Media
					:src="isMobile ? items.imgMobile : items.img"
					cover
					position="center"
					eager
					:aspect-ratio="801 / 673"
				></Media>
			</v-col>
		</v-row>
	</div>
</template>
<style scoped>
.mobile-title {
	font-size: 50px;
	line-height: 52px;
}
</style>
