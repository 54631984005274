<script>
export default {
	data() {
		return {
			items: this.$srv('About_Us_BigText', []),
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>

<template>
	<div
		class="text-uppercase text-center coral d-flex flex-column align-center"
		v-if="items"
		:class="isMobile && 'py-10'"
	>
		<div v-if="items.text" class="t1">{{ items.text }}</div>
		<div
			v-if="items.bigText"
			class="h4 font-weight-bold mt-5"
			style="max-width: 750px"
			:class="{ 'mobile-text h4': isMobile }"
		>
			{{ items.bigText }}
		</div>
	</div>
</template>
<style scoped>
.mobile-text {
	line-height: 45px;
}
</style>
