<script>
export default {
	metaInfo: {
		title: 'Sobre Nosotros',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>
<template>
	<div>
		<AboutUs-A-Header />
		<AboutUs-B-InfoWithImage class="pt-0 py-md-15" />
		<AboutUs-C-BigText style="padding: 100px 0" />
		<AboutUs-D-InfoWithImage class="py-15" />
	</div>
</template>
